import { isBrowser, checkUtmSourceStartsWith } from '../../utils';

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  const adUnits = [
    {
      condition: !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_') && !checkUtmSourceStartsWith('fb-'),
      id: 'LeaderBelowTitle',
      name: 'Leader_BellowTitle',
      path: '/76730613/Leader_BellowTitle',
      sizes: window.screen.width < 812 ? [[300, 250], [336, 280], [320, 100]] : [[728, 90]],
      bidders: [],
      mobile_bidders: [],
    },
    {
      condition: window.screen.width < 812,
      id: 'MPUTopRight',
      name: 'MPUTopRight',
      path: '/76730613/MPUTopRight',
      sizes: [[300, 250], [336, 280], [320, 100]],
      mobile_bidders: [],
    },
    {
      condition: window.screen.width > 812 && !checkUtmSourceStartsWith('fb-'),
      id: 'RightColumn',
      name: 'RightColumn1',
      path: '/76730613/RightColumn1',
      sizes: window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
      bidders: [],
    },
    {
      condition: window.screen.width > 812 && !checkUtmSourceStartsWith('yahoo_') && !checkUtmSourceStartsWith('fb-'),
      id: 'LeftColumn',
      name: 'LeftColumnHalfPage',
      path: '/76730613/LeftColumnHalfPage',
      sizes: window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
      bidders: [],
    },

    {
      condition: !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      id: 'StickyBanner',
      name: window.screen.width < 812 ? 'StickyBannerMobile' : 'StickyBannerDesktop',
      path: window.screen.width < 812 ? '/76730613/StickyBannerMobile' : '/76730613/StickyBannerDesktop',
      sizes: window.screen.width < 812 ? [[320, 50], [300, 50], [360, 50], [320, 100], [300, 100], [360, 100]] : [[728, 90]],
      bidders: [],
      mobile_bidders: [],
    },

    {
      condition: window.screen.width < 812,
      id: 'MPUCenterRight',
      name: 'MPUCenterRight',
      path: '/76730613/MPUCenterRight',
      sizes: [[300, 250], [320, 100], [336, 280]],
      mobile_bidders: [],
    },
    {
      condition: window.screen.width > 812,
      id: 'LeaderDesktopInfinite',
      name: 'LeaderDesktopInfinite',
      path: '/76730613/LeaderDesktopInfinite',
      sizes: [[728, 90]],
      bidders: [],
    },
    {
      condition: false,
      id: 'MPU_Parallax',
      name: 'MPU_Parallax',
      path: '/76730613/MPU_Parallax',
      sizes: [[336, 280], [320, 480], [300, 250], [300, 600], [160, 600]],
      bidders: [],
    },
  ];

  if (window.screen.width > 1440) {
    const rcMissingIX = adUnits.find((ad) => ad.id === 'RightColumn');
    const lcMissingIX = adUnits.find((ad) => ad.id === 'LeftColumn');
    rcMissingIX.bidders.push({ bidder: 'ix', params: { siteId: 752388, size: [300, 600] } });
    lcMissingIX.bidders.push({ bidder: 'ix', params: { siteId: 752381, size: [300, 600] } });
  }
  return adUnits;
}
