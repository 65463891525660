import { isBrowser, checkUtmSourceStartsWith } from '../../utils';

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  const adUnits = [
    {
      condition: !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      id: 'LeaderBelowTitle',
      name: 'NEXT_Leader_BellowTitle',
      path: '/76730613/NEXT_Leader_BellowTitle',
      sizes: window.screen.width < 812 ? [[300, 250], [336, 280], [320, 100]] : [[728, 90]],
      bidders: [],
      mobile_bidders: [],
    },
    {
      condition: window.screen.width < 812,
      id: 'MPUTopRight',
      name: 'NEXT_MPUTopRight',
      path: '/76730613/NEXT_MPUTopRight',
      sizes: [[300, 250], [336, 280], [320, 100]],
      mobile_bidders: [],
    },
    {
      condition: window.screen.width > 812,
      id: 'RightColumn',
      name: 'NEXT_RightColumnHalfPage1',
      path: '/76730613/NEXT_RightColumnHalfPage1',
      sizes: window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
      bidders: [],
    },
    {
      condition: window.screen.width > 812 && !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      id: 'LeftColumn',
      name: 'NEXT_LeftColumnHalfPage',
      path: '/76730613/NEXT_LeftColumnHalfPage',
      sizes: window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
      bidders: [],
    },
    {
      condition: !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      id: 'StickyBanner',
      name: window.screen.width < 812 ? 'NEXT_StickyBannerMobile' : 'NEXT_StickyBannerDesktop',
      path: window.screen.width < 812 ? '/76730613/NEXT_StickyBannerMobile' : '/76730613/NEXT_StickyBannerDesktop',
      sizes: window.screen.width < 812 ? [[320, 50], [300, 50], [360, 50], [320, 100], [300, 100], [360, 100]] : [[728, 90]],
      bidders: [],
      mobile_bidders: [],
    },
    {
      id: 'MPUBelowNext',
      name: 'NEXT_MPUBelowNext',
      path: '/76730613/NEXT_MPUBelowNext',
      sizes: window.screen.width < 812 ? [[300, 250], [336, 280], [320, 100]] : [[300, 250], [336, 280]],
      bidders: [],
      mobile_bidders: [],
    },
    {
      id: 'MPUCenterRight',
      name: 'NEXT_MPUCenterRight',
      path: '/76730613/NEXT_MPUCenterRight',
      sizes: window.screen.width < 812 ? [[300, 250], [336, 280], [320, 100]] : [[300, 250], [336, 280]],
      bidders: [],
      mobile_bidders: [],
    },
  ];

  /**
   * Finds left and right columns, pushes the missing bidders
   * and pushes correct bidders to the adunit
   */
  if (window.screen.width > 1440) {
    const rcMissingIX = adUnits.find((ad) => ad.id === 'RightColumn');
    const lcMissingIX = adUnits.find((ad) => ad.id === 'LeftColumn');
    rcMissingIX.bidders.push({ bidder: 'ix', params: { siteId: 752386, size: [300, 600] } });
    lcMissingIX.bidders.push({ bidder: 'ix', params: { siteId: 752383, size: [300, 600] } });
  }
  return adUnits;
}
