import * as React from "react";
import { IntlProvider } from "react-intl";
import { graphql } from "gatsby";

import { Helmet } from "react-helmet";
import SeoStructure from "../components/seo-structure";
import Layout from "../components/layout";

import LocalesContext from "../context/LocalesContext";
import messages from "../../i18n-translations.json";
import { defaultLanguage } from "../i18n";

import IndexPage from "../components/IndexPage";

const MainPage = (props) => {
  const site = props.data.site;
  const language = props.data.allWpCategory.length > 0 ? props.data.allWpCategory.nodes[0].language.name : process.env.GATSBY_DEFAULT_LANG;
  
  return (
    <IntlProvider locale={defaultLanguage} messages={messages[defaultLanguage]}>
      <LocalesContext.Provider
        value={{
          slug: "",
          avaiableLangs: ["en", "de", "es"],
          pageNumber: 0,
        }}
      >
        <Layout props={props}>
          <Helmet>
            <script
              async
              src="https://boot.pbstck.com/v1/tag/8c704138-0cf5-43be-9bf5-416e674a06d9"
              type="text/javascript"
            />       
          </Helmet>
          <SeoStructure
            title= "Home"
            slug="/"
          >
            <script type="application/ld+json">{
              JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'WebPage',
                '@id': `${!site.id ? '' : site.id}`,
                'name': `${!process.env.GATSBY_SITE_NAME ? '' : process.env.GATSBY_SITE_NAME}`,
                'url': `${!process.env.GATSBY_SITE_URL ? '' : process.env.GATSBY_SITE_URL}`,
                'inLanguage': `${!language ? '' : language}`,
                'isPartOf': {
                  '@type': 'WebPage',
                  '@id': `${!site.id ? '' : site.id}`,
                  'url': `${!process.env.GATSBY_SITE_URL ? '' : process.env.GATSBY_SITE_URL}`,
                  'name': `${!process.env.GATSBY_SITE_NAME ? '' : process.env.GATSBY_SITE_NAME}`,
                  'description': `${!site.siteMetadata.description ? '' : site.siteMetadata.description}`,
                  'inLanguage': `${!language ? '' : language}`,
                },
                'potentialAction': {
                  '@type': 'ReadAction',
                  'target': {
                    '@type': 'EntryPoint',
                    'urlTemplate': `${!process.env.GATSBY_SITE_URL ? '' : process.env.GATSBY_SITE_URL}`
                  }
                }
              })}
            </script>
          </SeoStructure>
          <IndexPage data={props.data} />
        </Layout>
      </LocalesContext.Provider>
    </IntlProvider>
  );
};

export default MainPage;

export const query = graphql`
  query ($defaultCategorySlugs: [String], $defaultLang: String) {
    allWpCategory: allWpCategory(
      filter: {
        slug: { nin: $defaultCategorySlugs }
        language: { slug: { eq: $defaultLang } }
        count: { gte: 4 }
      }
      sort: { fields: name, order: ASC }
      limit: 4
    ) {
      nodes {
        id
        name
        slug
        posts {
          nodes {
            id
            slug
            title
            excerpt
            originalSlug
            featuredImage {
              node {
                title
                mediaItemUrl
                mediaDetails {
                  filteredSizes {
                    name
                    sourceUrl
                  }
                  sizes {
                    name
                  }
                }
              }
            }
            categories {
              nodes {
                name
                slug
              }
            }
            seo {
              title
              metaDesc
              focuskw
              metaKeywords
              opengraphTitle
              opengraphDescription
              opengraphImage {
                altText
                sourceUrl
                srcSet
              }
              twitterTitle
              twitterDescription
              twitterImage {
                altText
                sourceUrl
                srcSet
              }
              canonical
              cornerstone
              schema {
                articleType
                pageType
                raw
              }
            }
          }
        }
        language {
          slug
          name
        }
      }
    }

    site: site {
      siteMetadata {
        title
        description
        siteUrl
      }
      id
    }

    heroPost: wpPost(
      language: { slug: { eq: "en" } }
      categories: {
        nodes: { elemMatch: { slug: { regex: "/hero-post-[a-zA-Z]+/" } } }
      }
    ) {
      id
      title
      excerpt
      originalSlug
      featuredImage {
        node {
          title
          mediaItemUrl
          mediaDetails {
            filteredSizes {
              name
              sourceUrl
            }
            sizes {
              name
            }
          }
        }
      }
      categories {
        nodes {
          id
          nodeType
          name
          slug
        }
      }
    }

    featuredPosts: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { regex: "/featured-posts-[a-zA-Z]+/" } } }
        }
        language: { slug: { eq: $defaultLang } }
      }
      limit: 5
    ) {
      edges {
        node {
          id
          title
          excerpt
          originalSlug
          categories {
            nodes {
              id
              nodeType
              name
              slug
            }
          }
        }
      }
    }

    customPostTypeOne: allWpCustomPostTypeOne(
      filter: { language: { slug: { eq: $defaultLang } } }, 
      limit: 5
    ) {
      edges {
        node {
          id
          title
          slug
          excerpt
          featuredImage {
            node {
              title
              mediaItemUrl
              mediaDetails {
                filteredSizes {
                  name
                  sourceUrl
                }
                sizes {
                  name
                }
              }
            }
          }
        }
      }
    }

    customPostTypeVideos: allWpCustomPostTypeVideo(limit: 4){
      edges {
        node {
          id
          title
          content
        }
      }
    }

    customPostTypeTwo: allWpCustomPostTypeTwo(limit: 3) {
      edges {
        node {
          id
          title
          slug
          custom_field_one {
            customPostTypeField
          }
          featuredImage {
            node {
              title
              mediaItemUrl
              mediaDetails {
                filteredSizes {
                  name
                  sourceUrl
                }
                sizes {
                  name
                }
              }
            }
          }
        }
      }
    }

  }

`;
