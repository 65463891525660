import React, { useEffect } from "react";
import { loadScript } from "../../utils";

function TaboolaWidget( { mode, container, placement, utm } ) {
    
    useEffect(() => {

        loadScript(
            "",
            true,
            false,
            false,
            `
                window._taboola = window._taboola || [];
                _taboola.push({article:'auto', tracking_codes:{ 'utm_source': '${utm}'} });
                !function (e, f, u, i) {
                if (!document.getElementById(i)){
                    e.async = 1;
                    e.src = u;
                    e.id = i;
                    f.parentNode.insertBefore(e, f);
                }
                }(document.createElement('script'),
                document.getElementsByTagName('script')[0], 
                '//cdn.taboola.com/libtrc/trendscatchers-network/loader.js',
                'tb_loader_script');
                if(window.performance && typeof window.performance.mark == 'function')
                {window.performance.mark('tbl_ic');}
            `
        );

        loadScript(
            "",
            false,
            false,
            false,
            `
            window._taboola = window._taboola || [];
            window._taboola.push({
                external_page_view:
                    ((window.performance.timing.navigationStart + window.performance.now()) * Math.random() * 1000000).toFixed() +
                    "|" +
                    window.location.host,
            });
            window._taboola.push({
                mode: '${mode}',
                container: '${container}',
                placement: '${placement}',
                target_type: 'mix',
                tracking_codes: "${utm}"

            });
        `);

        loadScript(
            "",
            false,
            false,
            true,
            `
             window._taboola = window._taboola || [];
            _taboola.push({flush: true});
        `)
    }, [mode, container, placement, utm]);
      
    return (
        <div>
            <div id={container}>
            </div>
        </div>
    );
      
}
export default TaboolaWidget