import { isBrowser, checkUtmSourceStartsWith } from '../../utils';

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  const onemobileDCN = window.screen.width > 420 ? '8a96945901757509a7551d43776b0342' : '8a96945901757509a7551d6362c80344';

  return [
    {
      condition: !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      id: 'LeaderBelowTitle',
      name: 'NEXT_Leader_BellowTitle',
      path: '/76730613/NEXT_Leader_BellowTitle',
      sizes: window.screen.width < 812 ? [[300, 250], [336, 280], [320, 100]] : [[728, 90]],
      bidders: [],
      mobile_bidders: [],
    },
    {
      condition: window.screen.width < 812,
      id: 'MPUTopRight',
      name: 'NEXT_MPUTopRight',
      path: '/76730613/NEXT_MPUTopRight',
      sizes: [[300, 250], [336, 280], [320, 100]],
      mobile_bidders: [],
    },
    {
      condition: window.screen.width > 812,
      id: 'RightColumn',
      name: 'NEXT_RightColumnHalfPage1',
      path: '/76730613/NEXT_RightColumnHalfPage1',
      sizes: window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
      bidders: [],
    },
    {
      condition: window.screen.width > 812 && !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      id: 'LeftColumn',
      name: 'NEXT_LeftColumnHalfPage',
      path: '/76730613/NEXT_LeftColumnHalfPage',
      sizes: window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
      bidders: [],
    },
  ];
}
